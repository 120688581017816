import Apiv3 from '../services/api/apiv3'
import { addCart, getCart, getPaymentsMethods } from '../services/api/cart'
import User from './User'

export default class Cart {
  constructor() {
    this.itens = []
    this.qty = 0
    this.total = 0
    this.totalInstallment = null
    this.shippingMethods = null
    this.shippingMethodsList = null
    this.shipping = { method_id: null, cost: 0, zip_code: null, deadline: 20 }
    this.person = null
    this.address = null
    this.payment = null
    this.discount = 0
    this.lastInvoice = null
    this.artsFiles = null
    this.paymentsMethods = null
    this.observacao = null

    this._subscribers = []
  }

  setTotalInstallment(value) {
    this.totalInstallment = value
    this.notify()
  }

  setTotal(value) {
    this.total = value
    this.notify()
  }

  setArtFiles(files) {
    this.artsFiles = files
  }

  reset() {
    this.itens = []
    this.qty = 0
    this.total = 0
    this.discount = 0
    this.shippingMethods = null
    this.shippingMethodsList = null
    this.shipping = { method_id: null, cost: 0, zip_code: null, deadline: 20 }
    this.person = null
    this.address = null
    this.payment = null
    this.artsFiles = null
    this.observacao = null
  }

  setAddress = (address) => {
    this.address = address
    this.shipping = { ...this.shipping, zip_code: address.cep }
    this.notify()
  }

  setShipping = (data) => {
    this.shipping = { ...this.shipping, ...data, cost: data?.valor || 0, deadline: data?.prazo || 20 }
    this.notify()
  }

  setPerson = (person) => {
    this.person = person
    this.address = null
    //this.shipping = { method_id: null, cost: null, zip_code: null, deadline : 20 };
    this.notify()
  }

  setPayment = (payment) => {
    this.payment = payment
    const DiscountPercent = 0
    this.discount = payment.code == 'pagseg_bol' ? this.total * DiscountPercent : 0 //Desconto de 5% no boleto
    this.notify()
  }

  setObservacao = (observacao) => {
    this.observacao = observacao
    this.notify()
  }

  removeAddress = (address) => {
    if (this.address) {
      if (this.address.id === address.id) {
        this.address = null
        this.notify()
      }
    }
  }

  subscribe = (func) => {
    this._subscribers.push(func)
  }

  unsubscribe(func) {
    this._subscribers = this._subscribers.filter((f) => f !== func)
  }

  notify() {
    const cart = {
      itens: this.itens,
      qty: this.qty,
      total: this.total,
      discount: this.discount,
      totalInstallment: this.totalInstallment,
      shippingMethods: this.shippingMethods,
      shippingMethodsList: this.shippingMethodsList,
      shipping: this.shipping,
      person: this.person,
      address: this.address,
      payment: this.payment,
      artsFiles: this.artsFiles,
      observacao: this.observacao,
    }
    this._subscribers.forEach((func) => func(cart))
  }

  addCart = async (loja_id, produto_id, qtd, gravacao_id) => {
    try {
      const item = { loja_id, produto_id, qtd, gravacao_id }
      const resp = await addCart(item)
      this.itens = resp.data.data;
      this.qty = resp.data.qty;
      //this.refresh();
      this.notify();
    } catch (error) {
      console.log(error)
    }
  }

  update = async (id, loja_id, qty) => {
    try {
      const resp = await Apiv3.put(`/cart/${id}`, { loja_id, qtd: qty });
      this.itens = resp.data.data;
      this.qty = resp.data.qty;
      this.notify();
      this.refresh()
    } catch (error) {
      console.log(error)
    }
  }

  delete = async (id) => {
    try {
      const resp = await Apiv3.delete(`/cart/${id}`);
      this.itens = resp.data.data;
      this.qty = resp.data.qty;
      //this.notify();
      this.refresh()
    } catch (error) {
      console.log(error)
    }
  }

  async refresh() {
    try {
      const cart = await getCart()
      this.itens = cart.data.data
      //this.paymentsMethods = cart.data.paymentsMethods
      this.qty = cart.data.qty
      this.total = cart.data.total
      this.discount = 0
      this.observacao = null
      const user = new User()
      await user.setUser()
      //this.person = user.pessoa;

      if (this.shipping.zip_code) await this.shippingCost(this.shipping.zip_code)

      this.notify()
    } catch (error) {
      console.log(error)
    }
  }

  async shippingCost(zip_code, products) {
    //if(this.shipping.zip_code != zip_code || this.shipping.cost === null){
    try {
      let data = await Apiv3.post(`/calcular-frete/ecommerce`, { cep: zip_code, products })
      this.shipping.zip_code = zip_code
      this.shipping.cost = 0
      this.shipping.method_id = null
      this.shipping.deadline = null

      data = data.data

      this.shippingMethods = data

      let combinedShippingMethods = []

      for (let key in data) {
        combinedShippingMethods = combinedShippingMethods?.concat(data[key])
      }
      this.shippingMethodsList = combinedShippingMethods?.map((item, index) => ({
        ...item,
        id: index,
      }))

      // Ordenando pelo menor preço
      this.shippingMethodsList.sort((a, b) => a.valor - b.valor)

      // Mantendo método atual ao navegar nas etapas de compra
      const currenShipping =
        this.shippingMethodsList?.find((item) => item?.id === this?.shipping?.id) || this?.shippingMethodsList?.[0]

      this.shipping = {
        ...this?.shipping,
        ...currenShipping,
        cost: currenShipping?.valor || 0,
        deadline: currenShipping?.prazo,
      }

      this.notify()
    } catch (error) {
      this.shipping.cost = 0
      this.shipping.method_id = null
      this.shipping.deadline = null
      throw error
    }
    //}
    //this.notify();
  }

  clearShipping() {
    this.shipping = { method_id: null, cost: 0, zip_code: null }
  }

  async createOrder(loja_id, userData) {
    try {
      const creditCardInfo = userData?.user?.creditCard
      const formData = new FormData()
      const requestBody = {
        loja_id: loja_id,
        payment: this.payment.code,
        method_id: this.shipping.method_id,
        shipping_cost: this.shipping.cost,
        shipping_deadline: this.shipping.deadline || 30,
        address_id: this.address.id,
        pessoa_id: this.person.id,
        user_id: userData?.user?.id,
        observacao: this.observacao,
      }

      if (creditCardInfo) {
        requestBody.cardToken = creditCardInfo?.cardToken || null
        requestBody.holderName = creditCardInfo ? creditCardInfo?.holderName : null
        requestBody.holderCPF = creditCardInfo ? creditCardInfo?.holderCPF : null
        requestBody.number = creditCardInfo ? creditCardInfo?.number : null
        requestBody.ccv = creditCardInfo ? creditCardInfo?.cvv : null
        requestBody.expiryMonth = creditCardInfo ? creditCardInfo?.validate?.slice(0, 2) : null
        requestBody.expiryYear = creditCardInfo ? '20' + creditCardInfo?.validate?.slice(2) : null
        requestBody.installmentCount = creditCardInfo ? creditCardInfo?.installmentCount : null
        requestBody.totalValue = creditCardInfo?.totalValue.toFixed(2)
        requestBody.name = creditCardInfo?.name
        requestBody.email = creditCardInfo?.email
        requestBody.cpfCnpj = creditCardInfo?.cpfCnpj
        requestBody.postalCode = creditCardInfo?.postalCode
        requestBody.addressNumber = creditCardInfo?.addressNumber
        requestBody.phone = creditCardInfo?.phone || null
        requestBody.mobilePhone = creditCardInfo?.mobilePhone || null
      }

      if (this.artsFiles) {
        requestBody.art_files = Object.values(this.artsFiles)
      }

      const resp = await Apiv3.post(`/order`, requestBody)

      this.lastInvoice = resp.data
      this.refresh()

      return resp.data
    } catch (error) {
      throw error
    }
  }

  async fetchPaymentsMethods(loja_id) {
    try {
      const payments = await getPaymentsMethods(loja_id);
      this.paymentsMethods = payments.data;
      this.notify();
      return this.paymentsMethods;
    } catch (error) {
      console.error(error)
    }
  }
}
